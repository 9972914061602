import { Question } from './Question';
import { CheckQuestion } from './CheckQuestion';
import { LinkedQuestion } from './LinkedQuestion';
import { MultipleChoiceQuestion } from './MultipleChoiceQuestion';
import { TrueFalseQuestion } from './TrueFalseQuestion';
import { writable } from 'svelte/store';
var Test = /** @class */ (function () {
    function Test(test) {
        this.title = test.title;
        this.description = test.description;
        this.submitted = false;
        this.questions = this.initQuestions(test.testQuestions);
        this.store = writable(this.getComponentFormat());
    }
    //// METHODS
    Test.prototype.initQuestions = function (input) {
        var questions = [];
        for (var i = 0; i < input.length; i++) {
            var question = this.initQuestion(i, input[i]);
            questions.push(question);
        }
        return questions;
    };
    Test.prototype.initQuestion = function (index, testQuestion) {
        if (testQuestion.questionType == 'CheckQuestion') {
            return new CheckQuestion(index, testQuestion.order, testQuestion.question.body, testQuestion.question.bodyFormat, testQuestion.question.pseudocode, testQuestion.question.checkQuestionOptions);
        }
        else if (testQuestion.questionType == 'LinkedQuestion') {
            return new LinkedQuestion(index, testQuestion.order, testQuestion.question.body, testQuestion.question.bodyFormat, testQuestion.question.pseudocode, testQuestion.question.linkedQuestionOptions, testQuestion.question.linkedQuestionAnswers);
        }
        else if (testQuestion.questionType == 'MultipleChoiceQuestion') {
            return new MultipleChoiceQuestion(index, testQuestion.order, testQuestion.question.body, testQuestion.question.bodyFormat, testQuestion.question.pseudocode, testQuestion.question.multipleChoiceAnswers);
        }
        else if (testQuestion.questionType == 'TrueFalseQuestion') {
            return new TrueFalseQuestion(index, testQuestion.order, testQuestion.question.body, testQuestion.question.bodyFormat, testQuestion.question.pseudocode, testQuestion.question.answer);
        }
        return new Question(index, testQuestion.order, testQuestion.question.body, testQuestion.question.bodyFormat, testQuestion.question.pseudocode);
    };
    Test.prototype.selectAnswer = function (questionIndex, answerIndex) {
        this.questions[questionIndex].selectAnswer(answerIndex);
        this.store.set(this.getComponentFormat());
    };
    Test.prototype.calculateScore = function () {
        var score = 0;
        if (this.submitted) {
            this.questions.forEach(function (question) {
                if (question instanceof MultipleChoiceQuestion) {
                    if (question.isAnsweredCorrectly()) {
                        score += 1;
                    }
                }
                else {
                    score += 1;
                }
            });
        }
        return score;
    };
    Test.prototype.submit = function () {
        this.calculateScore();
        this.setSubmitted(true);
        this.store.set(this.getComponentFormat());
        window.scrollTo(0, 0);
    };
    Test.prototype.getComponentFormat = function () {
        var questions = [];
        for (var i = 0; i < this.questions.length; i++) {
            questions.push(this.questions[i].getComponentFormat(this.submitted));
        }
        return {
            title: this.title,
            description: this.description,
            questions: questions,
            score: this.calculateScore()
        };
    };
    //// GETTERS and SETTERS
    Test.prototype.setSubmitted = function (submitted) {
        this.submitted = submitted;
    };
    Test.prototype.getStore = function () {
        return this.store;
    };
    return Test;
}());
export { Test };
