var MultipleChoiceAnswer = /** @class */ (function () {
    function MultipleChoiceAnswer(i, l, b, bf, c) {
        this.index = i;
        this.letter = l;
        this.body = b;
        this.bodyFormat = bf;
        this.correct = c;
    }
    MultipleChoiceAnswer.prototype.getComponentFormat = function (selectedAnswer, answerIndex, submitted) {
        return {
            index: this.index,
            letter: this.letter,
            body: this.body,
            bodyFormat: this.bodyFormat,
            state: this.getSelected(selectedAnswer, answerIndex, submitted)
        };
    };
    MultipleChoiceAnswer.prototype.getSelected = function (selectedAnswer, answerIndex, submitted) {
        if (submitted &&
            this.correct &&
            this.isSelected(selectedAnswer, answerIndex)) {
            return 'correct';
        }
        else if (submitted &&
            this.correct &&
            !this.isSelected(selectedAnswer, answerIndex)) {
            return 'solution';
        }
        else if (submitted &&
            !this.correct &&
            this.isSelected(selectedAnswer, answerIndex)) {
            return 'incorrect';
        }
        else if (this.isSelected(selectedAnswer, answerIndex)) {
            return 'selected';
        }
        return 'unselected';
    };
    MultipleChoiceAnswer.prototype.isSelected = function (selectedAnswer, answerIndex) {
        return selectedAnswer === answerIndex;
    };
    MultipleChoiceAnswer.prototype.isCorrect = function (selectedAnswer, answerIndex) {
        return this.correct && this.isSelected(selectedAnswer, answerIndex);
    };
    return MultipleChoiceAnswer;
}());
export { MultipleChoiceAnswer };
