import { __extends } from "tslib";
import { Question } from './Question';
import { LinkedQuestionOption } from './LinkedQuestionOption';
import { LinkedQuestionAnswer } from './LinkedQuestionAnswer';
var LinkedQuestion = /** @class */ (function (_super) {
    __extends(LinkedQuestion, _super);
    function LinkedQuestion(i, // Index
    n, // Q Number
    b, // Q Body
    bf, // Q Body format
    p, // Pseudocode (if any)
    o, // Options
    a // Answers
    ) {
        var _this = _super.call(this, i, n, b, bf, p) || this;
        _this.options = _this.initOptions(o);
        _this.answers = _this.initAnswers(a);
        return _this;
    }
    LinkedQuestion.prototype.initOptions = function (input) {
        var options = [];
        for (var i = 0; i < input.length; i++) {
            options.push(new LinkedQuestionOption(input[i].body, input[i].format));
        }
        return options;
    };
    LinkedQuestion.prototype.initAnswers = function (input) {
        var answers = [];
        for (var i = 0; i < input.length; i++) {
            answers.push(new LinkedQuestionAnswer(input[i].body, input[i].format, input[i].optionIndex));
        }
        return answers;
    };
    LinkedQuestion.prototype.isAnsweredCorrectly = function () {
        for (var i = 0; i < this.options.length; i++) {
            if (this.answers[this.options[i].selectedAnswerIndex].optionIndex != i) {
                return false;
            }
        }
        return true;
    };
    return LinkedQuestion;
}(Question));
export { LinkedQuestion };
