import { __extends } from "tslib";
import { Question } from './Question';
var TrueFalseQuestion = /** @class */ (function (_super) {
    __extends(TrueFalseQuestion, _super);
    function TrueFalseQuestion(i, // Index
    n, // Q Number
    b, // Q Body
    bf, // Q Body format
    p, // Pseudocode (if any)
    a // Actual answer (true/false)
    ) {
        var _this = _super.call(this, i, n, b, bf, p) || this;
        _this.actualAnswer = a;
        return _this;
    }
    TrueFalseQuestion.prototype.selectAnswer = function (bool) {
        this.selectedAnswer = bool;
    };
    TrueFalseQuestion.prototype.isAnsweredCorrectly = function () {
        return (this.selectedAnswer = this.actualAnswer);
    };
    return TrueFalseQuestion;
}(Question));
export { TrueFalseQuestion };
