var LinkedQuestionOption = /** @class */ (function () {
    function LinkedQuestionOption(b, f) {
        this.body = b;
        this.format = f;
        this.selectedAnswerIndex = -1;
    }
    LinkedQuestionOption.prototype.selectAnswer = function (answerIndex) {
        this.selectedAnswerIndex = answerIndex;
    };
    return LinkedQuestionOption;
}());
export { LinkedQuestionOption };
