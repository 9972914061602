import { __extends } from "tslib";
import { Question } from './Question';
import { CheckQuestionOption } from './CheckQuestionOption';
var CheckQuestion = /** @class */ (function (_super) {
    __extends(CheckQuestion, _super);
    function CheckQuestion(i, // Index
    n, // Q Number
    b, // Q Body
    bf, // Q Body format
    p, // Pseudocode (if any)
    o // Actual answer (true/false)
    ) {
        var _this = _super.call(this, i, n, b, bf, p) || this;
        _this.options = _this.initOptions(o);
        return _this;
    }
    CheckQuestion.prototype.initOptions = function (input) {
        var options = [];
        for (var i = 0; i < input.length; i++) {
            options.push(new CheckQuestionOption(input[i].body, input[i].format, input[i].isCorrect));
        }
        return options;
    };
    CheckQuestion.prototype.isAnsweredCorrectly = function () {
        for (var i = 0; i < this.options.length; i++) {
            if (this.options[i].isCorrect != this.options[i].isSelected) {
                return false;
            }
        }
        return true;
    };
    return CheckQuestion;
}(Question));
export { CheckQuestion };
