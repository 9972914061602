var CheckQuestionOption = /** @class */ (function () {
    function CheckQuestionOption(b, f, c) {
        this.body = b;
        this.format = f;
        this.isCorrect = c;
        this.isSelected = false;
    }
    CheckQuestionOption.prototype.changeAnswer = function () {
        this.isSelected = !this.isSelected;
    };
    return CheckQuestionOption;
}());
export { CheckQuestionOption };
