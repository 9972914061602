var Question = /** @class */ (function () {
    function Question(i, n, b, bf, p) {
        this.index = i;
        this.number = n;
        this.body = b;
        this.bodyFormat = bf;
        this.pseudocode = p;
    }
    Question.prototype.getComponentFormat = function (submitted) {
        return {
            number: this.number,
            body: this.body,
            bodyFormat: this.bodyFormat
        };
    };
    Question.prototype.selectAnswer = function (answerIndex) { };
    return Question;
}());
export { Question };
