import { __extends } from "tslib";
import { Question } from './Question';
import { MultipleChoiceAnswer } from './MultipleChoiceAnswer';
var MultipleChoiceQuestion = /** @class */ (function (_super) {
    __extends(MultipleChoiceQuestion, _super);
    function MultipleChoiceQuestion(i, n, b, bf, p, a) {
        var _this = _super.call(this, i, n, b, bf, p) || this;
        _this.selectedAnswer = -1;
        _this.answers = _this.initAnswers(a);
        return _this;
    }
    MultipleChoiceQuestion.prototype.initAnswers = function (input) {
        var answers = [];
        var letters = ['a', 'b', 'c', 'd'];
        for (var i = 0; i < input.length; i++) {
            var answer = new MultipleChoiceAnswer(i, letters[i], input[i].body, input[i].bodyFormat, input[i].correct);
            answers.push(answer);
        }
        return answers;
    };
    MultipleChoiceQuestion.prototype.selectAnswer = function (answerIndex) {
        this.selectedAnswer = answerIndex;
    };
    MultipleChoiceQuestion.prototype.isAnsweredCorrectly = function () {
        if (this.selectedAnswer == -1) {
            return false;
        }
        else if (this.answers[this.selectedAnswer].correct) {
            return true;
        }
        else {
            return false;
        }
    };
    MultipleChoiceQuestion.prototype.getComponentFormat = function (submitted) {
        var answers = [];
        for (var i = 0; i < this.answers.length; i++) {
            answers.push(this.answers[i].getComponentFormat(this.selectedAnswer, i, submitted));
        }
        return {
            index: this.index,
            number: this.number,
            body: this.body,
            bodyFormat: this.bodyFormat,
            pseudocode: this.pseudocode,
            answers: answers
        };
    };
    return MultipleChoiceQuestion;
}(Question));
export { MultipleChoiceQuestion };
